
import CompForm from "@/components/comp-form.vue"
import Request from "@/module/request/request"

export default {
    components: {
        CompForm,
    },

    data() {
        return {
            structure: null,
            uuid: null,
        }
    },

    activated() {
        // 获取表单结构
        this.getFormStructure()
        // 获取表单ID
        this.uuid = this.$route.query.id
    },

    methods: {
        getFormStructure() {
            Request.get("WEB://SetMeal/GetFormStructure").then(res => {
                this.structure = res
            })
        },
    },
}
